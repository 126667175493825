export function onKeyDown(onClick) {
  if (!onClick) return function () {};
  return function (event) {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick(event);
    }
  };
}
export function getClickableProps(onClick) {
  var role = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'button';
  var tabIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  if (!onClick) return {};
  return {
    role: role,
    tabIndex: tabIndex,
    onClick: onClick,
    onKeyDown: onKeyDown(onClick)
  };
}