function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { getSigninUrl } from '@/auth/utils';
import { NavLinkButton } from '@/components/ui/router';
import UIText from '@/components/ui/text';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref2 = true ? {
  name: "jjjty3",
  styles: "display:flex;flex-direction:column;align-items:center;> :not([hidden]) ~ :not([hidden]){--tw-space-y-reverse:0;margin-top:calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));margin-bottom:calc(1.25rem * var(--tw-space-y-reverse));}"
} : {
  name: "172st0j-OrganizationError",
  styles: "display:flex;flex-direction:column;align-items:center;> :not([hidden]) ~ :not([hidden]){--tw-space-y-reverse:0;margin-top:calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));margin-bottom:calc(1.25rem * var(--tw-space-y-reverse));};label:OrganizationError;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var OrganizationError = function OrganizationError(_ref) {
  var errorMessage = _ref.errorMessage;
  var router = useRouter();
  return _jsxs("div", {
    css: _ref2,
    children: [_jsx(UIText, {
      variant: "danger",
      children: errorMessage
    }), _jsx(NavLinkButton, {
      to: getSigninUrl(router),
      variant: "primary",
      size: "semiXlarge",
      textTransform: "uppercase",
      children: _jsx(FormattedMessage, {
        id: "dialog.action.back"
      })
    })]
  });
};
export default OrganizationError;