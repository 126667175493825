function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import React, { useEffect } from 'react';
import auth from '@motimate/auth';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { useOrganizationQuery } from '@/lib/hooks/useOrganization';
import { getIdentifier } from '@/lib/url-utils';
import UIButton from '@/components/ui/button';
import UILoader from '@/components/ui/loader';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = true ? {
  name: "1lgyv03",
  styles: "> :not([hidden]) ~ :not([hidden]){--tw-space-y-reverse:0;margin-top:calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));margin-bottom:calc(1.25rem * var(--tw-space-y-reverse));}"
} : {
  name: "lgmntc-LoginThirdParty",
  styles: "> :not([hidden]) ~ :not([hidden]){--tw-space-y-reverse:0;margin-top:calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));margin-bottom:calc(1.25rem * var(--tw-space-y-reverse));};label:LoginThirdParty;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var LoginThirdParty = function LoginThirdParty() {
  var _organization$colors$;
  var router = useRouter();
  var _useOrganizationQuery = useOrganizationQuery({
      redux: false
    }),
    organization = _useOrganizationQuery.data;
  var stateParam = 'state' in router.query ? String(router.query.state) : null;
  var loginThirdPartyAuth = function loginThirdPartyAuth() {
    var thridPartyLoginUrl = auth.getThirdPartyLoginUrl(getIdentifier(), stateParam);
    window.location.assign(thridPartyLoginUrl);
  };
  useEffect(function () {
    loginThirdPartyAuth();
  }, []);
  return _jsxs("div", {
    css: _ref,
    children: [_jsx(FormattedMessage, {
      id: "loginForm.redirecting",
      tagName: "div"
    }), _jsx(UILoader, {}), _jsx(FormattedMessage, {
      id: "loginForm.redirectingFails",
      tagName: "div"
    }), _jsx(UIButton, {
      size: "semiXlarge",
      variant: "primary",
      textTransform: "uppercase",
      $themeColorPrimary: (_organization$colors$ = organization === null || organization === void 0 ? void 0 : organization.colors.main) !== null && _organization$colors$ !== void 0 ? _organization$colors$ : '',
      onClick: function onClick() {
        return loginThirdPartyAuth();
      },
      children: _jsx(FormattedMessage, {
        id: "loginForm.submitButton",
        tagName: "span"
      })
    })]
  });
};
export default LoginThirdParty;