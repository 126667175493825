import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import auth from '@motimate/auth';
import Router from 'next/router';
import { p } from '@/lib/path-utils';
var PRODUCTION_MOBILE_APP_REDIRECT_URI = 'motimate-training://external-auth-completed';
var STAGING_MOBILE_APP_REDIRECT_URI = 'motimate-training-staging://external-auth-completed';
var getQueryRedirectUri = function getQueryRedirectUri(router) {
  return 'redirect_uri' in router.query ? String(router.query.redirect_uri) : undefined;
};
export var isProductionMobileApp = function isProductionMobileApp(router) {
  return getQueryRedirectUri(router) === PRODUCTION_MOBILE_APP_REDIRECT_URI;
};
export var isStagingMobileApp = function isStagingMobileApp(router) {
  return getQueryRedirectUri(router) === STAGING_MOBILE_APP_REDIRECT_URI;
};
export var getIsMobileApp = function getIsMobileApp(router) {
  return isProductionMobileApp(router) || isStagingMobileApp(router);
};
export var getNextUrlForToken = function getNextUrlForToken(token, redirectUri, stateParam) {
  var params = new URLSearchParams();
  switch (token.type) {
    case 'auth_code':
      params.append('code', token.value);
      if (stateParam) params.append('state', stateParam);
      return "".concat(redirectUri, "?").concat(params.toString());
    case 'new_authenticator':
      params.append('redirect_uri', redirectUri);
      if (stateParam) params.append('state', stateParam);
      return p("/mfa/setup/".concat(token.value, "?").concat(params.toString()));
    case 'otp_challange':
      params.append('redirect_uri', redirectUri);
      if (stateParam) params.append('state', stateParam);
      return p("/mfa/otp/".concat(token.value, "?").concat(params.toString()));
    default:
      throw new Error("Unknown token type: ".concat(token.type));
  }
};
export var loginMfa = function loginMfa(token, redirectUri, stateParam) {
  if (!redirectUri) throw RedirectUriError;
  window.location.assign(getNextUrlForToken(token, redirectUri, stateParam));
};
export var getSearch = function getSearch() {
  var router = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Router;
  var clientId = 'client_id' in router.query ? String(router.query.client_id) : undefined;
  var redirectUri = 'redirect_uri' in router.query ? String(router.query.redirect_uri) : undefined;
  var stateParam = 'state' in router.query ? String(router.query.state) : undefined;
  var params = new URLSearchParams();
  if (clientId) params.append('client_id', clientId);
  if (redirectUri) params.append('redirect_uri', redirectUri);
  if (stateParam) params.append('state', stateParam);
  return _toConsumableArray(params.entries()).length ? "?".concat(params.toString()) : '';
};
export var getOrganizationSigninUrl = function getOrganizationSigninUrl() {
  var router = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Router;
  var usesMfa = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var search = getSearch(router);
  return usesMfa ? p("/mfa/signin".concat(search), router) : p("/signin".concat(search), router);
};
export var getRedirectUri = function getRedirectUri(organization, router) {
  if (isProductionMobileApp(router)) return PRODUCTION_MOBILE_APP_REDIRECT_URI;
  if (isStagingMobileApp(router)) return STAGING_MOBILE_APP_REDIRECT_URI;
  return auth.getRedirectUri(organization);
};
export var getSigninUrl = function getSigninUrl() {
  var router = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Router;
  return "/signin".concat(getSearch(router));
};
export var getUserIdentifier = function getUserIdentifier(user, organization) {
  var identifierType = auth.getIdentifierType(organization);
  if (identifierType === 'email' || identifierType === 'employee_number_or_email' && user.email) {
    return user.email;
  }
  if (identifierType === 'employee_number' || identifierType === 'employee_number_or_email' && user.employee_number) {
    return user.employee_number;
  }
  throw new Error('Cannot get user identifier');
};
export var RedirectUriError = new Error('Missing `redirect_uri` in URL');