import React, { useCallback, useEffect, useState } from 'react';
import auth from '@motimate/auth';
import { useRouter } from 'next/router';
import { useOrganizationQuery } from '@/lib/hooks/useOrganization';
import { p } from '@/lib/path-utils';
import { sessionStorage } from '@/lib/storage';
import AuthLayout from '@/auth/Layout';
import Login from '@/auth/Login';
import OrganizationError from '@/auth/components/OrganizationError';
import AppDocumentTitle from '@/components/ui/document-title';
import UILoader from '@/components/ui/loader';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var OrganizationSigninPage = function OrganizationSigninPage() {
  var _error$response$data$, _error$response;
  var router = useRouter();
  var organization = router.query.organization;
  var users = auth.getListOfUsers();
  var _useOrganizationQuery = useOrganizationQuery({
      redux: true
    }, {
      enabled: false
    }, Array.isArray(organization) ? null : organization),
    isLoading = _useOrganizationQuery.isLoading,
    error = _useOrganizationQuery.error,
    refetch = _useOrganizationQuery.refetch;
  var _useState = useState(),
    initialErrorMessage = _useState[0],
    setInitialErrorMessage = _useState[1];
  var handleLoginSuccess = useCallback(function () {
    var _router$query$state;
    var defaultRedirectUrl = p('/');
    var stateParam = ((_router$query$state = router.query.state) !== null && _router$query$state !== void 0 ? _router$query$state : '').toString();
    var state = auth.retrieveState(stateParam);
    var redirectUrl = state === null || state === void 0 ? void 0 : state.redirectUrl;
    router.push(redirectUrl !== null && redirectUrl !== void 0 && redirectUrl.startsWith(defaultRedirectUrl) || redirectUrl !== null && redirectUrl !== void 0 && redirectUrl.startsWith('/settings') ? redirectUrl : defaultRedirectUrl);
  }, [router]);
  useEffect(function () {
    if (!organization || Array.isArray(organization)) return;
    if (users.includes(organization)) {
      handleLoginSuccess();
      return;
    }
    refetch();
  }, [router]);
  useEffect(function () {
    var errorMessage = sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem('error.message');
    if (!errorMessage) return;
    setInitialErrorMessage(errorMessage);
    sessionStorage === null || sessionStorage === void 0 || sessionStorage.removeItem('error.message');
  }, []);
  return _jsxs(AuthLayout, {
    children: [_jsx(AppDocumentTitle, {
      descriptors: ['titleSignInToMotiSpace']
    }), isLoading && _jsx(UILoader, {}), !isLoading && error && _jsx(OrganizationError, {
      errorMessage: (_error$response$data$ = (_error$response = error.response) === null || _error$response === void 0 || (_error$response = _error$response.data) === null || _error$response === void 0 ? void 0 : _error$response.error.message) !== null && _error$response$data$ !== void 0 ? _error$response$data$ : error.message
    }), !isLoading && !error && _jsx(Login, {
      initialErrorMessage: initialErrorMessage,
      onLoginSuccess: handleLoginSuccess
    })]
  });
};
export default OrganizationSigninPage;